import { trpc } from '@/api/client';
import dispatchToast from '@utils/dispatchToast';

export const useResendInvitation = (resetTimer: () => void) => {
  const utils = trpc.useContext();
  const { mutateAsync, isLoading } = trpc.resendInvitationData.useMutation({
    onSuccess: async (_, { employeeId }) => {
      await utils.getEmployeeProfile.invalidate({ employeeId });
      dispatchToast({
        type: 'success',
        content: 'Email reenviado com sucesso',
      });
      resetTimer();
    },
    onError: (_) => {
      dispatchToast({
        type: 'error',
        content: 'Erro ao reenviar email',
      });
    },
  });

  const resendInvitation = async (input: {
    employeeId: string;
    forceSend?: boolean;
  }) => {
    await mutateAsync(input);
  };

  return {
    resendInvitation,
    loading: isLoading,
  };
};
