import { trpc } from '@/api/client';
import { useSession } from '@/common/user';
import dispatchToast from '@utils/dispatchToast';
import { ErrorBoundary } from '@utils/ErrorBoundary';

export const useRescheduleMassInvitations = () => {
  const { companyId } = useSession();

  const { mutate, isLoading } = trpc.rescheduleMassInvitations.useMutation({
    onSuccess: () => {
      dispatchToast({
        type: 'success',
        content: 'Reagendamento em massa realizado com sucesso!',
      });
    },
    onError: (error) => {
      ErrorBoundary.captureException({
        ...error,
        name: 'RescheduleMassInvitationsError',
      });
      dispatchToast({
        type: 'error',
        content:
          'Ocorreu um erro ao realizar o reagendamento em massa, por favor tente novamente mais tarde.',
      });
    },
  });

  const rescheduleMassInvitations = async (rescheduleDate: Date) => {
    await mutate({ companyId, rescheduleDate });
  };

  return { rescheduleMassInvitations, loading: isLoading };
};
