import {
  Dot,
  GenericProfilePicture,
  Tooltip,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  StyledCell,
  StyledCellDescription,
  StyledCellStatus,
  StyledCellStatusContainer,
  StyledCellTitle,
  StyledCellTitleContainer,
} from './styles';
import { EmployeeStatus } from 'bff/src/services/company-management.service';
import { HighlightText } from '@Components/HighlightText';
import { STATUS_NAME } from '@/constants';

enum STATUS_COLOR {
  ACTIVE = 'green',
  INACTIVE = 'gray',
  INVITATION_PENDING = 'purple',
  INVITATION_EXPIRED = 'yellow',
  INVITATION_SENT = 'blue',
  INVITATION_SCHEDULED = 'purple',
}

type EmployeeNameCellProps = {
  name: string;
  documentNumber: string;
  status: EmployeeStatus;
  searchQuery: string;
};

export const EmployeeNameCell = ({
  name,
  documentNumber,
  status,
  searchQuery,
}: EmployeeNameCellProps) => {
  return (
    <StyledCell>
      <GenericProfilePicture size={34} name={name} />
      <StyledCellTitleContainer>
        <StyledCellStatusContainer>
          <StyledCellTitle variant="body4" weight={500}>
            <HighlightText text={name} target={searchQuery} />
          </StyledCellTitle>
          <Tooltip title={STATUS_NAME[status]}>
            <StyledCellStatus>
              <Dot variant={STATUS_COLOR[status]} hasOutline={true} />
            </StyledCellStatus>
          </Tooltip>
        </StyledCellStatusContainer>
        <StyledCellDescription variant="body4">
          {documentNumber}
        </StyledCellDescription>
      </StyledCellTitleContainer>
    </StyledCell>
  );
};
