import { trpc } from '@/api/client';
import { useSession } from '@/common/user';
import { getFromLS, setInLS } from '@flash-tecnologia/hros-web-utility';
import { setEventTracking } from '@utils/analytics';
import dispatchToast from '@utils/dispatchToast';
import { ErrorBoundary } from '@utils/ErrorBoundary';
import dayjs from 'dayjs';

export const useResendMassInvitations = () => {
  const { userId: employeeId, companyId } = useSession();

  const { mutate: resendInviteMutation, isLoading } =
    trpc.resendMassInvitations.useMutation({
      onSuccess: (data) => {
        const { estimatedShippingTime } = data;
        setInLS({
          key: 'RESEND_INVITATIONS_AVAILABLE_IN',
          value: estimatedShippingTime,
        });
        dispatchToast({
          type: 'success',
          content: 'Reenvio em massa realizado com sucesso!',
        });
      },
      onError: (error) => {
        ErrorBoundary.captureException({
          ...error,
          name: 'ResendMassInvitationsError',
        });
        dispatchToast({
          type: 'error',
          content:
            'Ocorreu um erro ao realizar reenvio em massa, por favor tente novamente mais tarde.',
        });
      },
    });

  const resendMassInvitations = async () => {
    setEventTracking('backbone_resend_mass_invitations_confirm_clicked', {
      employeeId,
    });

    const localAvailableIn = await getFromLS('RESEND_INVITATIONS_AVAILABLE_IN');

    if (localAvailableIn) {
      const now = dayjs();
      const resendAvailableIn = dayjs(localAvailableIn);

      if (now.isBefore(resendAvailableIn)) {
        dispatchToast({
          type: 'warning',
          content: `Re-envio em massa disponível em ${resendAvailableIn.format(
            'HH:mm',
          )}hs`,
          description: 'Evite spam.',
        });
        return;
      }
    }

    await resendInviteMutation({ companyId });
  };

  return { resendMassInvitations, loading: isLoading };
};
