interface HighlightTextProps {
  text: string;
  target: string;
}

export const HighlightText = ({ text, target }: HighlightTextProps) => {
  if (!target) return <span>{text}</span>;

  const regex = new RegExp(`(${target})`, 'gi');
  const parts = text.split(regex);

  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === target.toLowerCase() ? (
          <strong key={index}>{part}</strong>
        ) : (
          part
        ),
      )}
    </span>
  );
};
