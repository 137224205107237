import { useFindCompanyGroups } from '@/hooks/useFindCompanyGroups';
import { MultiSelect } from '@flash-tecnologia/hros-web-ui-v2';
import { useMemo } from 'react';
import { useForm } from '../../../../context/FormContext';
import { StyledSelectInput } from './styles';

export const SelectGroupField = () => {
  const { groups } = useFindCompanyGroups();
  const { updateField, allFieldsDisabled } = useForm();

  const options = useMemo(
    () =>
      groups
        ? groups.map((group: { name: string; id: string }) => ({
            label: group.name,
            value: group.id,
          }))
        : [],
    [groups],
  );

  return options.length > 0 ? (
    <StyledSelectInput>
      <MultiSelect
        limitTags={5}
        onSelectChange={(_, groups) =>
          updateField(
            'groupIds',
            groups.map((g: { value: string }) => g.value),
          )
        }
        renderInput={() => null}
        fullWidth
        multiple
        label="Grupos"
        options={options}
        disabled={allFieldsDisabled}
      />
    </StyledSelectInput>
  ) : null;
};
