import { TextField, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledTextField = styled(TextField)<{
  compoundField: boolean;
}>`
  ${({ compoundField }) =>
    compoundField
      ? `
          display: flex;
          flex-direction: column;
          width: 100%;`
      : ``}
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;
export const CheckboxLabel = styled(Typography)``;

export const StyledSelectInput = styled.div`
  .multi-select-custom-theme div.MuiFilledInput-root {
    padding: 2px 0 10px 15px !important;
    height: 58px !important;
  }

  .multi-select-custom-theme label {
    padding-top: 4px !important;
    padding-left: 4px !important;
  }
  .MuiChip-root.multi-select-tag {
    margin-top: 12px !important;
    height: 24px !important;
    padding: 10px 8px !important;
    background-color: ${({ theme }) => theme.colors.secondary[95]} !important;
  }

  .MuiChip-label {
    padding-left: 0px !important;
  }

  .MuiChip-deleteIcon {
    margin-right: 0px !important;
  }

  .MuiFilledInput-input {
    padding-left: 0px !important;
    margin-top: 0px !important;
    transform: translateY(14px);
  }
`;
