import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { PermissionProfilesType } from '../../types';

import { HeaderButtons } from './components/HeaderButtons';

import { HeaderContainer, Subtitle, HeaderText } from './styled';

export const Header = ({ headerMenuDisabled }: PermissionProfilesType) => {
  return (
    <HeaderContainer>
      <HeaderText>
        <Typography variant="headline6">Equipe</Typography>
        <Subtitle>
          Gerencie cadastros da sua equipe e envie convites de acesso ao app da
          Flash.
        </Subtitle>
      </HeaderText>
      <HeaderButtons menuDisabled={headerMenuDisabled} />
    </HeaderContainer>
  );
};
