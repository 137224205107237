import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  StyledFilterContainer,
  StyledFilterContainerHeader,
  StyledFilterContainerHeaderSubtitle,
  StyledFilterContainerHeaderTitle,
  StyledSearchInputContainer,
  StyledTextField,
} from '../../styles';
import { StyledCheckBox, StyledItem, StyledList } from './styles';
import { STATUS_NAME } from '@/constants';
import { Dispatch, SetStateAction, useState } from 'react';
import { Filters } from '@/pages/PageTable/components/Table';
import { StatusEnum } from 'bff/src/routers';

type FilterStatusProps = {
  filter: string[];
  onFilter: Dispatch<SetStateAction<Filters>>;
};

export const FilterStatus = ({ filter, onFilter }: FilterStatusProps) => {
  const [search, setSearch] = useState('');
  const options = Object.entries(STATUS_NAME)
    .map(([key, value]) => ({
      key,
      value,
    }))
    .filter((option) => {
      return option.value.toLowerCase().includes(search.toLowerCase());
    });

  const handleSelect = (option: string) => {
    if (filter?.includes(option)) {
      const newStatusFilter = filter.filter((status) => status !== option);
      onFilter((prev) => ({
        ...prev,
        status: newStatusFilter as StatusEnum[],
      }));
      return;
    }

    onFilter(
      (prev) =>
        ({
          ...prev,
          status: prev.status !== null ? [...prev.status, option] : [option],
        }) as Filters,
    );
  };

  return (
    <StyledFilterContainer>
      <StyledFilterContainerHeader>
        <StyledFilterContainerHeaderTitle variant="headline9">
          Status
        </StyledFilterContainerHeaderTitle>
        <StyledFilterContainerHeaderSubtitle variant="body4">
          Ativo, Inativo, Aguardando convite, Convite enviado +2
        </StyledFilterContainerHeaderSubtitle>
      </StyledFilterContainerHeader>
      <StyledSearchInputContainer>
        <Icons size={18} name="IconSearch" />
        <StyledTextField
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Buscar"
        />
      </StyledSearchInputContainer>
      <StyledList>
        {options.map((option) => {
          const active = filter.includes(option.key);
          return (
            <StyledItem
              onClick={() => handleSelect(option.key)}
              active={active}
            >
              <StyledCheckBox checked={active} />
              <Typography variant="body4">{option.value}</Typography>
            </StyledItem>
          );
        })}
      </StyledList>
    </StyledFilterContainer>
  );
};
