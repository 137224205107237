import { trpc } from '@/api/client';
import dispatchToast from '@Utils/dispatchToast';
import { ErrorBoundary } from '@utils/ErrorBoundary';

export const useCancelInvite = (onClose: () => void) => {
  const utils = trpc.useContext();
  const { mutate, isLoading } = trpc.cancelScheduledInvitation.useMutation({
    onSuccess: async (_, { employeeId }) => {
      await utils.getEmployeeProfile.invalidate({ employeeId });
      dispatchToast({
        type: 'success',
        content: 'Agendamento cancelado com sucesso!',
      });
      onClose();
    },
    onError: (error) => {
      ErrorBoundary.captureException({
        ...error,
        name: 'CancelScheduledInvitationError',
      });
      dispatchToast({
        type: 'error',
        content: 'Erro ao cancelar agendamento',
        description:
          'Nós não conseguimos cancelar este agendamento, tente novamente mais tarde.',
      });
    },
  });
  return { cancelInvite: mutate, loading: isLoading };
};
