import { trpc } from '@/api/client';
import {
  useSelectedCompany,
  useViewMode,
  VIEW_MODE_ENUM,
} from '@flash-tecnologia/hros-web-utility';
import { useMemo } from 'react';

export const useGetEmployeesReportNumbers = () => {
  const { selectedCompany } = useSelectedCompany();
  const { mode } = useViewMode();
  const isUnifiedVision = useMemo(
    () => mode === VIEW_MODE_ENUM.ECONOMIC_GROUP,
    [mode],
  );
  const { data, isLoading } = trpc.getEmployeesReport.useQuery({
    status: ['ACTIVE', 'INVITATION_SENT', 'INVITATION_EXPIRED'],
    ...(!isUnifiedVision && { companyId: selectedCompany.id }),
  });

  return { data, isLoading };
};
