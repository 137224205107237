import { LinkButton, PillButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 40px;
  margin-bottom: 20px;
`;

export const BoxesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const StyledIconButton = styled(PillButton)`
  width: 24px;
  height: 24px;
  margin-left: 12px;
`;

export const StyledButton = styled(LinkButton)`
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: start;
`;
