import { Radio, PillButton, Tag } from '@flash-tecnologia/hros-web-ui-v2';

import {
  TextContainer,
  RadioCard,
  RadioOptionText,
  OptionContainer,
  RadioCardRight,
  RadioCardLeft,
  StyledRadioTitleContainer,
} from '../styled';

import { StyledText, StyledTitle } from 'src/common/styles/general-styles';

interface IReactivateOptions {
  formik: any;
}

export const MigrateOptions = ({ formik }: IReactivateOptions) => {
  return (
    <OptionContainer>
      <TextContainer>
        <StyledTitle
          setColor="neutral30"
          variant="headline8"
          children="O que fazer com colaboradores já cadastrados no grupo econômico"
        />
        <StyledText
          setColor="neutral50"
          variant="body4"
          children="Defina o que fazer se alguma pessoa da planilha já estiver cadastrada em outra empresa do grupo econômico."
        />
      </TextContainer>
      <RadioCard>
        <RadioCardLeft>
          <Radio
            size="small"
            name="notMigrate"
            checked={formik.values.notMigrate}
            value={formik.values.notMigrate}
            onChange={() => {
              formik.setFieldValue('notMigrate', true);
              formik.setFieldValue('migrate', false);
            }}
            error={
              formik.touched.notMigrate && Boolean(formik.errors.notMigrate)
            }
          />
          <RadioOptionText>
            <StyledRadioTitleContainer>
              <StyledTitle setColor="neutral30" variant="body3">
                Duplicar cadastro
              </StyledTitle>
              <Tag size="small" variant="yellow">
                Não recomendado
              </Tag>
            </StyledRadioTitleContainer>
            <StyledText setColor="neutral50" variant="body4">
              A pessoa duplicada continuará na empresa atual e no CNPJ indicado
              na planilha.
            </StyledText>
          </RadioOptionText>
        </RadioCardLeft>
        <RadioCardRight>
          <PillButton
            variant="pink"
            size="medium"
            icon="IconUsers"
            type="primary"
          />
        </RadioCardRight>
      </RadioCard>
      <RadioCard>
        <RadioCardLeft>
          <Radio
            size="small"
            name="migrate"
            checked={formik.values.migrate}
            value={formik.values.migrate}
            onChange={() => {
              formik.setFieldValue('migrate', true);
              formik.setFieldValue('notMigrate', false);
            }}
            error={formik.touched.migrate && Boolean(formik.errors.migrate)}
          />
          <RadioOptionText>
            <StyledTitle
              setColor="neutral30"
              variant="body3"
              children="Transferir a pessoa para a nova empresa"
            />

            <StyledText
              setColor="neutral50"
              variant="body4"
              children={
                <>
                  Essas pessoas deixarão de estar vinculadas às empresas atuais
                  e passarão a estar vinculadas apenas à nova empresa.
                </>
              }
            />
          </RadioOptionText>
        </RadioCardLeft>
        <RadioCardRight>
          <PillButton
            variant="pink"
            size="medium"
            icon="IconSwitch3"
            type="primary"
          />
        </RadioCardRight>
      </RadioCard>
    </OptionContainer>
  );
};
