import { Employee } from 'bff/src/services/company-management.service';
import { trpc } from 'src/api/client';

const SEARCH_EMPLOYEE_LIMIT = 2;

type UseFindEmployeesInEconomicGroupOptions = {
  options?: {
    onFound?: (data: Employee[]) => void;
    onNotFound?: () => void;
  };
};

export const useFindEmployeesInEconomicGroup = ({
  options,
}: UseFindEmployeesInEconomicGroupOptions) => {
  const { mutateAsync } = trpc.getEmployeesPaginated.useMutation({
    cacheTime: 0,
  });
  const findEmployeesInEconomicGroup = async (documentNumber: string) => {
    const cleanedDocumentNumber = documentNumber.replace(/[^0-9a-zA-Z]/g, '');

    if (!cleanedDocumentNumber && options?.onNotFound) {
      options?.onNotFound();
      return;
    }

    const { employees } = await mutateAsync({
      pageSize: SEARCH_EMPLOYEE_LIMIT,
      page: 1,
      documentNumbers: cleanedDocumentNumber,
    });

    if (employees.length > 0) {
      if (options?.onFound) {
        options.onFound(employees as Employee[]);
      }
      return employees;
    } else {
      if (options?.onNotFound) {
        options.onNotFound();
      }
    }
  };

  return {
    findEmployeesInEconomicGroup,
  };
};
