import { useSession } from '@/common/user';
import {
  checkUnleashToggle,
  useUnleashToggles,
} from '@utils/checkUnleashToggle';
import { isValidCPF } from '@utils/isValidCpf';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { z, ZodError } from 'zod';

export enum FormTypeEnum {
  'FULL' = 'FULL',
  'SIMPLE' = 'SIMPLE',
}

interface FormContextType {
  form: FormType;
  formType: FormTypeEnum;
  selectFormType: React.Dispatch<React.SetStateAction<FormTypeEnum>>;
  updateField: (field: keyof FormType, value: FormType[keyof FormType]) => void;
  setDisableEmailField: React.Dispatch<React.SetStateAction<boolean>>;
  disableEmailField: boolean;
  errors: Record<string, string | undefined>;
  setErrors: React.Dispatch<
    React.SetStateAction<Record<string, string | undefined>>
  >;
  validateFields: () => boolean;
  allFieldsDisabled: boolean;
  setAllFieldsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  employeeIdForTransfer: string;
  setEmployeeIdForTransfer: React.Dispatch<React.SetStateAction<string>>;
  invitationType: InvitationType;
  setInvitationType: React.Dispatch<React.SetStateAction<InvitationType>>;
  canMigrate: boolean | undefined;
  setCanMigrate: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  canRegister: boolean | undefined;
  setCanRegister: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  willMigrate: boolean;
  setWillMigrate: React.Dispatch<React.SetStateAction<boolean>>;
  foundEmployee: boolean;
  setFoundEmployee: React.Dispatch<React.SetStateAction<boolean>>;
  isUserInternational: boolean | undefined;
  canUseCreateEmployeeByPassport: boolean | undefined;
  setIsUserInternational: React.Dispatch<React.SetStateAction<boolean>>;
}

const createFormSchema = (
  isEmailInputDisabled: boolean,
  isUserInternational: boolean,
  invitationType: InvitationType,
) => {
  return z.object({
    name: z.string().min(1, 'O nome é obrigatório'),
    documentNumber: !isUserInternational
      ? z
          .string()
          .min(1, 'O número do documento é obrigatório')
          .refine(isValidCPF, {
            message: 'O CPF informado é inválido',
          })
      : z.string().min(1, 'O número do documento é obrigatório'),
    email: !isEmailInputDisabled
      ? z.string().min(1, 'O e-mail é obrigatório')
      : z.string().optional(),
    phone: isEmailInputDisabled
      ? z.string().min(1, 'O número do telefone é obrigatório')
      : z.string().optional(),
    admissionDate: z.string().optional(),
    jobPositionId: z.string().optional(),
    groupIds: z.array(z.string()).optional(),
    departmentId: z.string().optional(),
    pis: z.string().optional(),
    externalId: z.string().optional(),
    managerId: z.string().optional(),
    roleId: z.string().optional(),
    invitationDate:
      invitationType === 'SCHEDULED'
        ? z.string({ required_error: 'A data é obrigatória' })
        : z.string().optional(),
  });
};

export type FormType = z.infer<ReturnType<typeof createFormSchema>>;

const FormContext = createContext<FormContextType | null>(null);

interface FormProviderProps {
  children: ReactNode;
}

type InvitationType = 'INSTANTLY' | 'SCHEDULED';

export const FormProvider: React.FC<FormProviderProps> = ({ children }) => {
  const [formType, selectFormType] = useState<FormTypeEnum>(
    FormTypeEnum.SIMPLE,
  );
  const [form, setForm] = useState<FormType>({
    name: '',
    documentNumber: '',
    email: '',
    phone: '',
  } as FormType);
  const [disableEmailField, setDisableEmailField] = useState(false);
  const [errors, setErrors] = useState<Record<string, string | undefined>>({});
  const [foundEmployee, setFoundEmployee] = useState<boolean>(false);
  const [allFieldsDisabled, setAllFieldsDisabled] = useState<boolean>(false);
  const [employeeIdForTransfer, setEmployeeIdForTransfer] = useState('');
  const [canMigrate, setCanMigrate] = useState<boolean | undefined>();
  const [canRegister, setCanRegister] = useState<boolean | undefined>();
  const [willMigrate, setWillMigrate] = useState<boolean>(true);
  const [isUserInternational, setIsUserInternational] =
    useState<boolean>(false);
  const [invitationType, setInvitationType] =
    useState<InvitationType>('INSTANTLY');

  const { economicGroupId } = useSession();
  const { flags } = useUnleashToggles({
    economicGroupId: economicGroupId,
  });

  const canUseCreateEmployeeByPassport = checkUnleashToggle(
    'FLASH_OS_ALLOW_CREATE_EMPLOYEE_BY_PASSPORT',
    flags,
  );

  const updateField = (
    field: keyof FormType,
    value: FormType[keyof FormType],
  ) => {
    setErrors((prevError) => ({
      ...prevError,
      [field]: undefined,
    }));
    setForm((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const validateFields = useCallback(() => {
    const schema = createFormSchema(
      disableEmailField,
      isUserInternational,
      invitationType,
    );

    const { success, error } = schema.safeParse(form) as {
      success: boolean;
      error: ZodError;
    };

    if (!success && error) {
      const { fieldErrors } = error.flatten();
      setErrors(
        Object.entries(fieldErrors).reduce(
          (err, [field, message]) => {
            if (message) {
              err[field] = message[0];
            }
            return err;
          },
          {} as Record<string, string>,
        ),
      );
    } else {
      setErrors({});
    }

    return success;
  }, [form, disableEmailField, isUserInternational, invitationType]);

  const values = useMemo(
    () => ({
      formType,
      selectFormType,
      updateField,
      form,
      disableEmailField,
      setDisableEmailField,
      validateFields,
      errors,
      setErrors,
      allFieldsDisabled,
      setAllFieldsDisabled,
      employeeIdForTransfer,
      setEmployeeIdForTransfer,
      invitationType,
      setInvitationType,
      canMigrate,
      setCanMigrate,
      canRegister,
      setCanRegister,
      willMigrate,
      setWillMigrate,
      foundEmployee,
      setFoundEmployee,
      isUserInternational,
      setIsUserInternational,
      canUseCreateEmployeeByPassport,
    }),
    [
      form,
      formType,
      errors,
      disableEmailField,
      allFieldsDisabled,
      employeeIdForTransfer,
      invitationType,
      canMigrate,
      canRegister,
      willMigrate,
      foundEmployee,
      isUserInternational,
      flags,
    ],
  );

  return <FormContext.Provider value={values}>{children}</FormContext.Provider>;
};

export const useForm = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useForm must be used within an FormProvider');
  }
  return context;
};
