import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  BoxesContainer,
  ReportContainer,
  StyledButton,
  StyledButtonContainer,
} from './styled';
import { StatusEnum } from '@/common/types/StatusEnum';
import { Filters } from '../Table';
import { useGetEmployeesReportNumbers } from '../../hooks/useGetEmployeesReportNumbers';
import { setEventTracking } from '@/utils';
import { BigNumber } from '../BigNumber';

const BUTTON_EVENTS = {
  ACTIVE: 'employees_bignumbers_active_button_cliked',
  INVITATION_EXPIRED: 'employees_bignumbers_expired_button_cliked',
  PENDING_FIRST_ACCESS: 'employees_bignumbers_pending_button_cliked',
};

export const Report = ({ setFilters, companyId, userId }: any) => {
  const handleFiltersChange = <TFilter extends keyof Filters>(
    value: Filters[TFilter],
  ) => {
    setFilters((prev: any) => ({
      ...prev,
      status: value,
    }));
  };
  const { data } = useGetEmployeesReportNumbers();

  return (
    <ReportContainer>
      <BoxesContainer>
        <BigNumber
          title="Pessoas com cadastro ativo"
          content={`${data?.[StatusEnum.ACTIVE] || '0'} pessoas`}
          onClick={() => {
            handleFiltersChange([StatusEnum.ACTIVE]);
            setEventTracking(BUTTON_EVENTS.ACTIVE, {
              employeeId: userId,
              companyId,
            });
          }}
          icon="IconUserCheck"
          loading={data?.[StatusEnum.ACTIVE] === undefined}
        />
        <BigNumber
          title="Pessoas com primeiro acesso pendente"
          content={`${data?.[StatusEnum.INVITATION_SENT] || '0'} pessoas`}
          onClick={() => {
            handleFiltersChange([StatusEnum.INVITATION_SENT]);
            setEventTracking(BUTTON_EVENTS.PENDING_FIRST_ACCESS, {
              employeeId: userId,
              companyId,
            });
          }}
          icon="IconUserExclamation"
          loading={data?.[StatusEnum.INVITATION_SENT] === undefined}
        />
        <BigNumber
          title="Pessoas com primeiro acesso expirado"
          content={`${data?.[StatusEnum.INVITATION_EXPIRED] || '0'} pessoas`}
          onClick={() => {
            handleFiltersChange([StatusEnum.INVITATION_EXPIRED]);
            setEventTracking(BUTTON_EVENTS.INVITATION_EXPIRED, {
              employeeId: userId,
              companyId,
            });
          }}
          icon="IconMailOff"
          loading={data?.[StatusEnum.INVITATION_EXPIRED] === undefined}
        />
      </BoxesContainer>
    </ReportContainer>
  );
};
