import { useSession } from '@/common/user';
import withAdditionalLabel from '@/pages/PageTable/components/ModalCreateEmployee/hocs/WithAdditionalLabel';
import { useFindEmployeesInEconomicGroup } from '@/pages/PageTable/components/ModalCreateEmployee/hooks/useFindEmployeeInEconomicGroup';
import { TextField } from '@flash-tecnologia/hros-web-ui-v2';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';
import {
  checkUnleashToggle,
  useUnleashToggles,
} from '@utils/checkUnleashToggle';
import { Employee } from 'bff/src/services/company-management.service';
import { useMemo } from 'react';
import { FormTypeEnum, useForm } from '../../../../context/FormContext';
import { useCanMigrateEmployee } from '../../../../hooks/useCanMigrateEmployee';
import { StyledFieldWrapper } from '../../styles';
import { ErrorCaption } from '../ErrorCaption';

const TextFieldWithAdditionalLabel = withAdditionalLabel(TextField);

export const DocumentNumberField = () => {
  const {
    errors,
    updateField,
    form,
    setAllFieldsDisabled,
    setErrors,
    setEmployeeIdForTransfer,
    selectFormType,
    setCanMigrate,
    setCanRegister,
    setWillMigrate,
    setFoundEmployee,
    isUserInternational,
  } = useForm();
  const { canMigrate } = useCanMigrateEmployee();
  const { economicGroupId } = useSession();
  const { selectedCompany } = useSelectedCompany();
  const { findEmployeesInEconomicGroup } = useFindEmployeesInEconomicGroup({
    options: {
      onFound: async (employees) => {
        const sameCompanyEmployee = employees.filter(
          (employee) => employee.companyId === selectedCompany.id,
        );
        sameCompanyEmployee.length
          ? await onFoundEmployee(sameCompanyEmployee[0])
          : await onFoundEmployee(employees[0]);
      },
      onNotFound: () => onNotFoundEmployee(),
    },
  });

  const { flags } = useUnleashToggles({
    economicGroupId: economicGroupId,
  });

  const canUseEmployeeMigration = useMemo(
    () => checkUnleashToggle('FLASH_OS_ALLOW_EMPLOYEE_MIGRATION', flags),
    [flags],
  );

  const onFoundEmployee = async (employee: Employee) => {
    // Repeated excerpt until the removal of unleash.
    setFoundEmployee(true);
    if (employee.companyId === selectedCompany.id) {
      updateField('name', employee.name);
      updateField('email', employee.email);
      updateField('phone', employee.phoneNumber);
      setAllFieldsDisabled(true);
      selectFormType(FormTypeEnum.SIMPLE);
      setCanRegister(false);
      setErrors((prevError) => ({
        ...prevError,
        employeeAlreadyExists:
          'O colaborador não pode ser cadastrado porque já há um colaborador com o mesmo CPF nesta empresa.',
      }));
      return;
    }

    if (!canUseEmployeeMigration) {
      return;
    }

    selectFormType(FormTypeEnum.SIMPLE);
    updateField('name', employee.name);
    updateField('email', employee.email);
    updateField('phone', employee.phoneNumber);

    const employeeCanMigrate = await canMigrate({ employeeId: employee.id });

    if (employeeCanMigrate.canMigrate) {
      setEmployeeIdForTransfer(employee.id);
      setCanMigrate(true);
      setAllFieldsDisabled(true);
      return;
    }
    setCanMigrate(false);
    setWillMigrate(false);
    setAllFieldsDisabled(false);
  };

  const onNotFoundEmployee = () => {
    setAllFieldsDisabled(false);
    setCanRegister(undefined);
    setCanMigrate(undefined);
    updateField('name', '');
    updateField('email', '');
    updateField('phone', '');
    setErrors((prevError) => ({
      ...prevError,
      employeeAlreadyExists: undefined,
      documentNumberInCurrentCompany: undefined,
    }));
  };

  const hasError = useMemo(
    () => !!errors['documentNumber'] || !!errors['employeeAlreadyExists'],
    [errors],
  );

  return (
    <StyledFieldWrapper>
      <TextFieldWithAdditionalLabel
        additionalLabel={
          <>
            {errors.documentNumber && (
              <ErrorCaption>{errors.documentNumber}</ErrorCaption>
            )}
            {errors.employeeAlreadyExists && (
              <ErrorCaption>{errors.employeeAlreadyExists}</ErrorCaption>
            )}
          </>
        }
        fullWidth
        label={!isUserInternational ? 'CPF' : 'Passaporte'}
        placeholder={!isUserInternational ? 'CPF' : 'Passaporte'}
        required={!hasError}
        onBlur={() => {
          findEmployeesInEconomicGroup(form.documentNumber);
        }}
        onChange={(e) => updateField('documentNumber', e.target.value)}
        value={form.documentNumber}
        error={hasError}
        {...(!isUserInternational && {
          imaskProps: { mask: '000.000.000-00' },
        })}
      />
    </StyledFieldWrapper>
  );
};
