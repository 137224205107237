import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledItem = styled.div<{ active: boolean }>`
  padding: 12px;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.secondary[99] : theme.colors.neutral[100]};
  color: ${({ theme, active }) =>
    active ? theme.colors.secondary[30] : theme.colors.neutral[30]};
  display: flex;
  align-items: center;
  gap: 12px;
  border-radius: ${({ theme }) => theme.borders.radius.s};
  cursor: pointer;
`;

export const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 250px;
  overflow-y: auto;
  padding-right: 8px;
`;

export const StyledCheckBox = styled(Checkbox)`
  transform: scale(0.7);
  padding: 0px;
`;
