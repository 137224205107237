import {
  Button,
  Icons,
  Menu,
  PillButton,
} from '@flash-tecnologia/hros-web-ui-v2';
import dispatchToast from '@utils/dispatchToast';
import * as SC from './styles';
import { useFetchCompanySpecialRedemptionCode } from '@/pages/PageTable/components/Table/components/CustomAccessMenu/useFetchCompanySpecialRedemptionCode';
import { env } from '@/lib/env';

export const CustomAccessMenu = () => {
  const { specialRedemptionCode, isLoading } =
    useFetchCompanySpecialRedemptionCode();

  if (isLoading || !specialRedemptionCode) return null;

  return (
    <Menu
      type={'select'}
      disableAutoFocus
      disableAutoFocusItem
      options={[
        {
          children: (
            <SC.OptionContainer>
              <Icons name="IconCopy" fill="transparent" />
              Copiar link de primeiro acesso
            </SC.OptionContainer>
          ),
          onClick: () => {
            navigator.clipboard.writeText(
              `${env.HROS_URL}/authentication/firstAccess/custom/${specialRedemptionCode}`,
            );
            dispatchToast({
              type: 'success',
              content: 'Link copiado com sucesso!',
            });
          },
        },
      ]}
    >
      <PillButton
        label="Primeiro acesso"
        icon="IconLink"
        variant="default"
        size="small"
      />
    </Menu>
  );
};
