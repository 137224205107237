import {
  TextField,
  Typography,
  Unstable,
} from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledDrawer = styled(Unstable.Drawer)`
  &.MuiModal-root {
    div {
      &.MuiPaper-root {
        max-width: 600px;
        width: 45%;
        padding: 40px 40px 0;
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
  padding-bottom: ${({ theme }) => theme.spacings.xs};
`;

export const StyledFilterContainer = styled.div`
  border: solid 1px ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  padding: 20px;
`;

export const StyledFilterContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledFilterContainerHeaderTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const StyledFilterContainerHeaderSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledSearchInputContainer = styled.div`
  padding: 6px 10px;
  position: relative;
  border: solid 1px ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.pill};
  display: flex;
  align-items: center;
  gap: 2px;
  margin: 10px 0px;
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
  color: ${(props) => props.theme.colors.neutral[30]};
  font-weight: 600;

  & .MuiInputBase-input {
    border: none !important;
    padding: 0px 10px !important;
    font-size: 14px;
  }

  & > .MuiFilledInput-root {
    border: none !important;
  }
`;
