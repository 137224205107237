import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

export const StyledUnifiedVisionBanner = styled.div`
  width: 100%;
  padding: 12px 24px;
  background-color: #d4e8ff;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 1s ease-out;
`;

export const StyledUnifiedVisionBannerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  animation: ${fadeIn} 1.2s ease-out;
`;

export const StyledUnifiedVisionBannerIcon = styled(Icons)`
  color: #015fcb;
  animation: ${fadeIn} 1.5s ease-out;
`;

export const StyledUnifiedVisionBannerTitle = styled(Typography)`
  color: #015fcb;
  transform: translateY(1px);
  animation: ${fadeIn} 1.3s ease-out;
`;
