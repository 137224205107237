import { Button, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { FooterContainer } from './styled';

interface FooterProps {
  loading?: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export const Footer = ({ loading, onClose, onConfirm }: FooterProps) => {
  return (
    <FooterContainer>
      <LinkButton onClick={onClose} variant="neutral">
        Cancelar
      </LinkButton>
      <Button
        variant="primary"
        size="medium"
        type="submit"
        loading={loading}
        onClick={onConfirm}
      >
        Confirmar
      </Button>
    </FooterContainer>
  );
};
