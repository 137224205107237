import { StyledDrawer } from './styles';
import { FilterStatus } from './components/FilterStatus';
import { Filters } from '../../../..';
import { Dispatch, SetStateAction } from 'react';

type FilterDrawerProps = {
  open: boolean;
  onClose: () => void;
  filters: Filters;
  onFilter: Dispatch<SetStateAction<Filters>>;
};

export const FilterDrawer = ({
  open,
  onClose,
  filters,
  onFilter,
}: FilterDrawerProps) => {
  return (
    <StyledDrawer title="Filtros" open={open} onClose={() => onClose()}>
      <FilterStatus filter={filters.status || []} onFilter={onFilter} />
    </StyledDrawer>
  );
};
