import { Dropzone, IconButton, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import dispatchToast from '@Utils/dispatchToast';
import { useState } from 'react';
import axios from 'axios';

import {
  StyledModal,
  FooterContainer,
  FooterCancelButton,
  SubmitButton,
  ContentContainer,
  HeaderContainer,
  HeaderTextWrapper,
  HeaderTitle,
  HeaderDescription,
  ContentTextWrapper,
  ContentTitle,
  ContentDescription,
} from './styled';
import { trpc } from '@/api/client';

export const ModalEditProfilePicture = ({
  isOpen,
  onClose,
  employeeId,
}: {
  isOpen: boolean;
  onClose: () => void;
  employeeId: string;
}) => {
  const [droppedFile, setDroppedFile] = useState<{ file: Blob }[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync } = trpc.updateEmployeeProfilePicture.useMutation();
  const context = trpc.useContext();

  const uploadProfilePic = async () => {
    try {
      if (!droppedFile?.[0]?.file) return;
      setIsLoading(true);
      const { uploadUrl } = await mutateAsync({ employeeId });

      await axios.put(uploadUrl, droppedFile[0].file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      await context.getEmployeeProfile.refetch();

      setIsLoading(false);
      dispatchToast({
        type: 'success',
        content: 'Edição salva com sucesso!',
      });

      onClose();
    } catch (error) {
      dispatchToast({
        content:
          'Sentimos muito, ocorreu um erro ao atualizar sua foto de perfil.',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledModal
      open={isOpen}
      onClose={onClose}
      header={
        <HeaderContainer>
          <HeaderTextWrapper>
            <HeaderTitle variant="headline6">Enviar foto</HeaderTitle>
            <HeaderDescription variant="body4">
              Escolha uma foto recente e com boa qualidade. Nós aceitamos os
              arquivos que estão no formato .JPG, .PNG e .PDF até 5MB.
            </HeaderDescription>
          </HeaderTextWrapper>
          <IconButton
            variant="line"
            size="small"
            icon="IconX"
            onClick={onClose}
          />
        </HeaderContainer>
      }
      footer={
        <FooterContainer>
          <FooterCancelButton onClick={onClose}>
            <Icons name="IconArrowLeft" fill="transparent" size={24} />
            <span>Voltar</span>
          </FooterCancelButton>
          <SubmitButton
            variant={'primary'}
            buttonType={'primary'}
            size={'small'}
            style={{ margin: 'auto 0', padding: '19px 52px' }}
            onClick={() => uploadProfilePic()}
            loading={isLoading}
            disabled={!droppedFile?.length}
            disableRipple
            disableTouchRipple
          >
            <span>Salvar nova foto</span>
            <Icons name="IconPencil" fill="transparent" size={32} />
          </SubmitButton>
        </FooterContainer>
      }
    >
      <ContentContainer>
        <ContentTextWrapper>
          <ContentTitle variant="headline8">Enviar foto</ContentTitle>
          <ContentDescription variant="body4">
            Certifique-se de que sua imagem esteja bem enquadrada e centralizada
            para que toda a informação importante apareça em seu perfil.
          </ContentDescription>
        </ContentTextWrapper>
        <Dropzone
          title="Sua foto"
          accept={['png', 'jpg', 'jpeg']}
          onChange={(e) => setDroppedFile(e)}
        />
      </ContentContainer>
    </StyledModal>
  );
};
