import styled from 'styled-components';

export const TableContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;
