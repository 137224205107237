import { trpc } from '@/api/client';
import { FilterStatusEnum, StatusEnum } from '@/common/types/StatusEnum';

export const useGetEmployeesPaginated = () => {
  const { data, isLoading, mutate, mutateAsync } =
    trpc.getEmployeesPaginated.useMutation();

  const fetch = ({
    companyId,
    pageNumber,
    pageSize,
    status,
    search,
  }: {
    companyId?: string;
    pageNumber: number;
    pageSize: number;
    status?: FilterStatusEnum[] | StatusEnum[];
    search?: any;
  }) => {
    mutate({
      companyId,
      page: pageNumber,
      pageSize,
      status: status as any,
      searchQuery: search,
    });
  };

  return { data, isLoading, mutate, mutateAsync, fetch };
};
