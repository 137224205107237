import styled from 'styled-components';

import {
  DatePicker,
  TimePicker,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Typography)`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral[30]};
  }
`;

export const Row = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  gap: ${({ gap }) => gap};
`;

export const StyledDatePicker = styled(DatePicker)`
  && {
    && {
      &.date-picker-container {
        width: 100%;
      }
    }
  }
`;

export const StyledInputHourContainer = styled.div`
  border: solid 1px ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  height: 46px;
  width: 100%;
  padding: 4px 12px;
  color: ${({ theme }) => theme.colors.neutral[50]};
  cursor: not-allowed;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledInputHourTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
