import { useCallback, useMemo, useState } from 'react';

import { ITableGrid } from './types';

import {
  Badge,
  Checkbox,
  Icons,
  LinkButton,
  PillButton,
  Table,
  tableControllers,
  Tag,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';

import {
  StyledActionButton,
  StyledCompanyCell,
  StyledCompanyCellContainer,
  StyledCompanyDescription,
  StyledCompanyTitle,
  StyledTableOptions,
} from './styled';
import { EmployeeGroups } from './components/EmployeeGroups';
import { EmployeeOptions } from './components/EmployeeOptions';
import { useSession } from '@/common/user';
import { useNavigate } from 'react-router-dom';
import {
  checkUnleashToggle,
  useUnleashToggles,
} from '@utils/checkUnleashToggle';
import { EmployeeNameCell } from './components/EmployeeNameCell';
import { formatCPF } from '@utils/formatCpf';
import { formatPhoneNumber } from '@utils/formatPhoneNumber';
import { HighlightText } from '@Components/HighlightText';
import { CustomAccessMenu } from '../CustomAccessMenu';
import { BatchActionsModal } from '@Components/BatchActionsModal';
import { useInvitationCompany } from '@/hooks/useInvitationCompany';

export const TableGrid = ({
  data,
  loading,
  totalCount,
  onPagination,
  pagination,
  enabledUnifiedVision,
  onSearch,
  searchQuery,
  onOpenFilter,
  filters,
}: ITableGrid) => {
  const [selectedEmployees, setSelectedEmployees] = useState(new Set());
  const [allPageSelected, setAllPageSelected] = useState(false);
  const [allItemsSelected, setAllItemsSelected] = useState(false);
  const { resendMassIsOpen, onCloseResendModal, onOpenResendModal } =
    useInvitationCompany();
  const { authUser, userId } = useSession();
  const navigate = useNavigate();

  const { economicGroupId } = useSession();

  const { flags } = useUnleashToggles({
    economicGroupId: economicGroupId,
  });

  const canUseMigrateEmployeeFeature = useMemo(
    () => checkUnleashToggle('FLASH_OS_ALLOW_EMPLOYEE_MIGRATION', flags),
    [flags],
  );

  const handleSingleSelect = useCallback((employeeId: string) => {
    setSelectedEmployees((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(employeeId)) {
        newSet.delete(employeeId);
      } else {
        newSet.add(employeeId);
      }
      return newSet;
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        header: () => (
          <>
            <Checkbox
              checked={allPageSelected}
              onChange={() => handleSelectAllPage()}
              aria-label="Select row"
            />
          </>
        ),
        id: 'select',
        accessorKey: 'id',
        size: 50,
        cell: ({ row }: { row: any }) => (
          <>
            <Checkbox
              checked={selectedEmployees.has(row?.original?.id)}
              onChange={() => handleSingleSelect(row?.original?.id)}
              aria-label="Select row"
            />
          </>
        ),
      },
      {
        header: 'Nome',
        accessorKey: 'name',
        cell: (data: any) => {
          const employee = data?.cell?.row?.original;
          return (
            <EmployeeNameCell
              searchQuery={searchQuery}
              name={employee?.name}
              documentNumber={formatCPF(employee.documentNumber)}
              status={employee.status}
            />
          );
        },
      },
      {
        header: 'E-mail',
        accessorKey: 'email',
        cell: (data: any) => {
          const email = data?.cell?.row?.original?.email;
          return email ? (
            <HighlightText text={email} target={searchQuery} />
          ) : (
            '-'
          );
        },
      },
      {
        header: 'Telefone',
        accessorKey: 'phoneNumber',
        cell: (data: any) => {
          const phoneNumber = data?.cell?.row?.original?.phoneNumber;
          return phoneNumber ? (
            <HighlightText
              text={formatPhoneNumber(data?.cell?.row?.original?.phoneNumber)}
              target={searchQuery}
            />
          ) : (
            '-'
          );
        },
      },
      {
        header: 'Grupo',
        accessorKey: 'groups',
        cell: ({ row }: any) =>
          row?.original?.groups && (
            <EmployeeGroups groups={row?.original?.groups} />
          ),
      },
      {
        header: 'Ações',
        accessorKey: 'id',
        size: 70,
        cell: (data: any) => (
          <EmployeeOptions
            userId={userId}
            employeeId={data?.cell?.row?.original?.id}
            status={data?.cell?.row?.original?.status}
            authUser={authUser}
            documentNumber={data?.cell.row?.original.documentNumber}
            enableMigration={canUseMigrateEmployeeFeature}
          />
        ),
        sticky: 'right',
      },
    ],
    [
      selectedEmployees,
      allPageSelected,
      allItemsSelected,
      data,
      enabledUnifiedVision,
    ],
  );

  if (enabledUnifiedVision) {
    columns.splice(2, 0, {
      header: 'Empresa',
      accessorKey: 'company',
      cell: (data: any) => {
        const company = data?.cell?.row?.original?.company;
        return (
          <StyledCompanyCell>
            <PillButton
              icon="IconBuilding"
              onClick={() => null}
              variant="default"
              size="extra-small"
              type="primary"
            />
            <StyledCompanyCellContainer>
              <StyledCompanyTitle variant="body4">
                {company?.name}
              </StyledCompanyTitle>
              <StyledCompanyDescription variant="body4">
                {company?.registrationNumber}
              </StyledCompanyDescription>
            </StyledCompanyCellContainer>
          </StyledCompanyCell>
        );
      },
    });
  }

  const table = tableControllers.useTableColumns({
    defaultColumn: {
      minSize: 250,
      maxSize: 400,
    },
    total: data?.length || 0,
    columns: columns as any,
    data: data || [],
    pagination: pagination,
    onPaginationChange: onPagination,
  });

  const handleSelectAllPage = useCallback(() => {
    setAllPageSelected((prev) => {
      const isSelectingAll = !prev;
      if (isSelectingAll && data?.length > 0) {
        const currentEmployeeIds = data.map((employee) => employee.id);
        currentEmployeeIds.forEach((id) => {
          selectedEmployees.add(id);
        });
      } else {
        selectedEmployees.clear();
      }

      return isSelectingAll;
    });
  }, [data, selectedEmployees]);

  const handleToggleSelectAllItems = useCallback(() => {
    setAllItemsSelected((prev) => {
      const isSelectingAll = !prev;
      if (isSelectingAll && data?.length > 0) {
        const currentEmployeeIds = data.map((employee) => employee.id);
        currentEmployeeIds.forEach((id) => selectedEmployees.add(id));
      } else {
        selectedEmployees.clear();
        setAllPageSelected(false);
      }

      return isSelectingAll;
    });
  }, [data, selectedEmployees]);

  const resetSelectors = useCallback(() => {
    setAllItemsSelected(false);
    setAllPageSelected(false);
    selectedEmployees.clear();
  }, []);

  const buildQueryParams = useCallback(() => {
    if (allItemsSelected) {
      return 'employeeIds=ALL';
    }

    const employeeIds = Array.from(selectedEmployees).join(',');
    return `employeeIds=${employeeIds}`;
  }, [allItemsSelected, selectedEmployees]);

  return (
    <>
      <Table.Root variant="soft">
        <Table.Content>
          <Table.FilterSearch onSearch={(e) => onSearch(e.target.value)}>
            <PillButton
              icon="IconMailForward"
              label="Reenviar convite em massa"
              onClick={() => onOpenResendModal()}
              variant="default"
              size="small"
            />
            <CustomAccessMenu />
            <Badge
              variant="primary"
              color="brand"
              size="lg"
              anchor="right-top"
              content={filters.status?.length || 0}
            >
              <PillButton
                icon="IconFilter"
                label="Filtros"
                onClick={() => onOpenFilter()}
                variant={
                  filters?.status && filters.status.length > 0
                    ? 'pink'
                    : 'default'
                }
                size="small"
                type="primary"
              />
            </Badge>
          </Table.FilterSearch>

          <Table.Grid.Root loading={loading}>
            {selectedEmployees.size > 0 || allPageSelected ? (
              <tr
                className="data-grid-table-header-bulk-actions-container"
                role="row"
              >
                <th className="data-grid-table-header-bulk-actions">
                  <Checkbox
                    onChange={() => handleSelectAllPage()}
                    indeterminate={allPageSelected}
                    checked={allPageSelected}
                    aria-label="column row checkbox"
                  />
                </th>
                <th
                  className="data-grid-table-header-bulk-actions"
                  style={{ paddingLeft: '0px' }}
                >
                  <StyledTableOptions>
                    <Tag as="span" size="small" variant="primary">
                      {allItemsSelected ? totalCount : selectedEmployees.size}{' '}
                      de {totalCount} selecionados
                    </Tag>
                    {allPageSelected && selectedEmployees.size < totalCount && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '16px',
                        }}
                      >
                        {allItemsSelected && (
                          <Typography variant="body4">
                            Todos os {totalCount} itens foram selecionados.
                          </Typography>
                        )}
                        <LinkButton
                          variant="default"
                          onClick={() => handleToggleSelectAllItems()}
                        >
                          {allItemsSelected
                            ? 'Limpar seleção'
                            : `Selecionar todos os ${totalCount} itens`}
                        </LinkButton>
                      </div>
                    )}
                    {canUseMigrateEmployeeFeature && (
                      <LinkButton
                        onClick={() =>
                          navigate(
                            `/employees/multiple-migration?${buildQueryParams()}`,
                          )
                        }
                        variant="neutral"
                      >
                        <Icons
                          style={{ marginRight: '4px' }}
                          size={18}
                          name="IconArrowsShuffle"
                          fill="transparent"
                        />
                        Movimentar pessoas
                      </LinkButton>
                    )}
                  </StyledTableOptions>
                </th>
              </tr>
            ) : (
              <Table.Grid.Header
                getHeaderGroups={table.getHeaderGroups}
                toggleAllRowsExpanded={table.toggleAllRowsExpanded}
              />
            )}

            {table.rows.map((row, index) => (
              <Table.Grid.Row key={index + row.id} row={row} />
            ))}
          </Table.Grid.Root>

          <Table.Pagination
            count={totalCount}
            pagination={pagination}
            onPaginationChange={(value) => {
              onPagination(value);
              resetSelectors();
            }}
          />

          <Table.BulkActions
            open={
              table.selected.allSelected || table.selected.selected.length > 0
            }
            total={0}
            totalSelected={
              table.selected.allSelected ? 0 : table.selected.selected.length
            }
            onClearSelection={table.resetSelected}
            onSelectAll={() => table.setAllSelected(true)}
          >
            <PillButton
              label="Action A"
              icon="IconFilter"
              onClick={() => null}
              variant="default"
              type="primary"
              size="extra-small"
            />

            <PillButton
              label="Action B"
              icon="IconFilter"
              onClick={() => null}
              variant="pink"
              type="primary"
              size="extra-small"
            />

            <PillButton
              label="Action C"
              icon="IconFilter"
              onClick={() => null}
              variant="error"
              type="secondary"
              size="extra-small"
            />
          </Table.BulkActions>
        </Table.Content>
        <BatchActionsModal
          isOpen={resendMassIsOpen}
          onClose={onCloseResendModal}
        />
      </Table.Root>
    </>
  );
};
