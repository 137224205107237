import { ImportType } from '@/pages/PageEmployeesWorksheet/types';
import { IconTypes } from '@flash-tecnologia/hros-web-ui-v2';
interface Field {
  icon: IconTypes;
  title: string;
  tag?: string;
  bullets?: string[];
}

export interface FieldInstructions {
  header: {
    title: string;
    description: string;
  };
  fields: Field[];
}

export const buildInstructions = (
  operation: ImportType,
  simpleImport: boolean,
) => {
  if (operation === ImportType.deactivate) {
    return {
      instructions: fieldInstructions.fields.filter((field) =>
        ['CPF', 'CNPJ'].includes(field.title),
      ),
      fileConfig: fileColumns.filter((column) =>
        ['CPF', 'CNPJ'].includes(column),
      ),
    };
  }

  if (simpleImport) {
    return {
      instructions: fieldInstructions.fields.slice(0, 5),
      fileConfig: fileColumns.slice(0, 5),
    };
  }

  return {
    instructions: fieldInstructions.fields,
    fileConfig: fileColumns,
  };
};

export const fieldInstructions: FieldInstructions = {
  header: {
    title: 'Como formatar o arquivo para envio?',
    description:
      'Confira quais são os dados pedidos no modelo de planilha e como preenchê-los.',
  },
  fields: [
    {
      icon: 'IconUser',
      title: 'Nome completo',
      tag: 'Obrigatório',
    },
    {
      icon: 'IconId',
      title: 'CPF',
      tag: 'Obrigatório',
      bullets: [
        'É importante que a coluna esteja formatada como Texto, para que não oculte caracteres em CPFs que começam com zero.',
        'O uso de pontos e hífen é opcional.',
      ],
    },
    {
      icon: 'IconMail',
      title: 'E-mail de cadastro',
      tag: 'Obrigatório caso não possua o celular',
      bullets: [
        'Enviaremos o convite de primeiro acesso à plataforma e app da Flash para esse e-mail.',
        'Caso você não saiba o e-mail, preencha o celular para que o convite seja enviado por SMS.',
        'Certifique-se de incluir o nome do usuário seguido pelo símbolo @ e o nome do provedor de e-mail (por exemplo: nomedeusuario@provedor.com).',
      ],
    },
    {
      icon: 'IconDeviceMobile',
      title: 'Celular',
      tag: 'Obrigatório caso não possua o e-mail',
      bullets: [
        'Caso você não saiba o e-mail da pessoa, o preenchimento desse campo se torna obrigatório para que o convite de primeiro acesso seja enviado por SMS.',
        'É necessário preencher o DDD.',
        'O uso de parênteses e hífen é opcional.',
        'O código do país (ex: "+55") não deve ser preenchido.',
      ],
    },
    {
      icon: 'IconBuildingSkyscraper',
      title: 'CNPJ',
      tag: 'Obrigatório',
      bullets: [
        'É importante que a coluna esteja formatada como “Texto” para que não oculte caracteres em CNPJs que começam com zero.',
        'O uso de pontos e hífen é opcional.',
        'Esse campo define em qual empresa do grupo a pessoa será cadastrada.',
        'O uso de pontos e barra é opcional. ',
      ],
    },
    {
      icon: 'IconInfoSquare',
      title: 'Data de admissão',
      bullets: [
        'É importante que a coluna esteja formatada como "Texto".',
        'Esse campo define em qual data a pessoa que será cadastrada foi admitida na empresa.',
        'Os formatos suportados são: DD/MM/YYYY, DD-MM-YYYY ou YYYY-MM-DD.',
      ],
    },
    {
      icon: 'IconSubtask',
      title: 'Cargo',
      bullets: [
        'Esse campo define qual é o cargo atual da pessoa que será cadastrada.',
        'Se o cargo ainda não existir, será criado e associado a esta pessoa. ',
        'Fique atento à ortografia e aos acentos de cargos semelhantes, por exemplo: ”Analista de Marketing” e ”Analista de MKT”.',
      ],
    },
    {
      icon: 'IconSubtask',
      title: 'Grupos',
      bullets: [
        'Esse campo define qual é o grupo atual da pessoa que será cadastrada.',
        'Se o grupo ainda não existir, será criado e associado a esta pessoa. ',
        'Fique atento à ortografia e aos acentos de grupos semelhantes.',
      ],
    },
    {
      icon: 'IconCircles',
      title: 'Departamento',
      bullets: [
        'Esse campo define a qual departamento a pessoa que será cadastrada pertence.',
        'Se o departamento ainda não existir, será criado e associado a esta pessoa.',
        'Fique atento(a) à ortografia e aos acentos de departamentos semelhantes, por exemplo: “Marketing” e “MKT”.',
      ],
    },
    {
      icon: 'IconId',
      title: 'PIS',
      bullets: [
        'É importante que a coluna esteja formatada como “Texto” para que não oculte sequências que começam com zero.',
        'O uso de pontos e barra é opcional. ',
      ],
    },
    {
      icon: 'IconHierarchy2',
      title: 'Líder Imediato',
      bullets: [
        'O campo de líder imediato deve ser preenchido com o CPF da pessoa que você quer atribuir a liderança.',
        'É importante que a coluna esteja formatada como “Texto” para que não oculte CPFs que começam com zero.',
        'Este campo define quem é o líder ou superior imediato da pessoa que será cadastrada.',
      ],
    },
    {
      icon: 'IconIdBadge2',
      title: 'Matrícula',
      bullets: [
        'O campo de matrícula é o identificador da pessoa na empresa.',
        'É importante que a coluna esteja formatada como “Texto” para que não oculte caracteres em Matrícula que começam com zero.',
      ],
    },
  ],
};

export const fileColumns = [
  'Nome completo',
  'CPF',
  'Celular',
  'E-mail',
  'CNPJ',
  'Data de admissão',
  'Cargo',
  'Grupo',
  'Departamento',
  'PIS',
  'Líder imediato',
  'Matrícula',
];
