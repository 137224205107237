import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledBigNumber = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: solid 1px ${({ theme }) => theme.colors.neutral[90]};
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: 12px;
  padding: 12px 16px;
  position: relative;
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledNumber = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
`;

export const StyledButtonText = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
`;

export const StyledButtonIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.secondary[50]};
`;
export const StyledIconContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;
