import { dayjs } from '@flash-tecnologia/hros-web-ui-v2';
import { ImportType } from '../types';
export type CreateImportInput = {
  config: {
    action: string;
    invitationDate?: string;
    reactivate?: boolean;
  };
  fileDictionary: {
    name?: string | number;
    documentNumber: string | number;
    registrationNumber: string | number;
    phone?: string | number;
    email?: string | number;
    role?: string | number;
    department?: string | number;
    group?: string | number;
    managerDocumentNumber?: string | number;
    pis?: string | number;
    startDate?: string | number;
  };
  fileData: File;
  fileName: string;
  fileType: string;
  hasHeader: boolean;
  initialCell: string;
  separator: string;
};

export class CreateImportPayloadBuilder {
  static simpleFileDictionary: CreateImportInput['fileDictionary'] = {
    name: 'Nome completo',
    documentNumber: 'CPF',
    registrationNumber: 'CNPJ',
    phone: 'Celular',
    email: 'E-mail',
  };
  static completeFileDictionary: CreateImportInput['fileDictionary'] = {
    name: 'Nome completo',
    documentNumber: 'CPF',
    registrationNumber: 'CNPJ',
    phone: 'Celular',
    email: 'E-mail',
    role: 'Cargo',
    department: 'Departamento',
    group: 'Grupo',
    managerDocumentNumber: 'Líder imediato',
    pis: 'PIS',
    startDate: 'Data de admissão',
  };
  static deactivateFileDictionary: CreateImportInput['fileDictionary'] = {
    documentNumber: 'CPF',
    registrationNumber: 'CNPJ',
  };
  static actions = {
    insert: 'CREATE_ONLY',
    update: 'UPDATE_ONLY',
    deactivate: 'DEACTIVATE',
  };
  private config: CreateImportInput['config'] = {
    action: 'CREATE_ONLY',
    invitationDate: dayjs().format('YYYY-MM-DDT08:00:00'),
    reactivate: false,
  };
  private fileDictionary = CreateImportPayloadBuilder.completeFileDictionary;
  private hasHeader: boolean = true;
  private initialCell: string = 'A1';
  private separator = ',';

  withActionConfig({
    operation,
    reactivate,
    scheduleInvite,
    scheduleDate,
  }: {
    operation: ImportType;
    reactivate?: boolean;
    scheduleInvite?: boolean;
    scheduleDate?: Date;
  }): CreateImportPayloadBuilder {
    let sendDate = null;
    const dateNow = dayjs()
      .add(3, 'hour')
      .add(2, 'minute')
      .format('YYYY-MM-DDTHH:mm:ss');
    scheduleInvite &&
      (sendDate = dayjs(scheduleDate).format('YYYY-MM-DDT08:00:00'));
    this.config = {
      action: CreateImportPayloadBuilder.actions[operation],
      invitationDate: sendDate || dateNow,
      ...(reactivate && { reactivate }),
    };
    return this;
  }

  withFileDictionary(
    fileDictionary: Record<string, string | number>,
  ): CreateImportPayloadBuilder {
    let filteredDictionary: Record<string, string | number> = {};
    Object.keys(fileDictionary).forEach((keyItem) => {
      if (fileDictionary[keyItem] !== 'emptyColumn') {
        filteredDictionary[keyItem] = fileDictionary[keyItem];
      }
    });
    this.fileDictionary = {
      ...filteredDictionary,
      documentNumber: filteredDictionary.documentNumber,
      registrationNumber: filteredDictionary.registrationNumber,
    };
    return this;
  }

  withSeparator(separator: string): CreateImportPayloadBuilder {
    if (separator) this.separator = separator;
    return this;
  }

  build(droppedFile: any): CreateImportInput {
    return {
      config: this.config,
      fileDictionary: this.fileDictionary,
      fileData: droppedFile?.[0].file,
      fileName: droppedFile?.[0].file.name,
      fileType: droppedFile?.[0].file.type,
      hasHeader: this.hasHeader,
      initialCell: this.initialCell,
      separator: this.separator,
    };
  }
}
