import { useFormik } from 'formik';
import { ImportType } from '../types';
import * as yup from 'yup';

type Field = {
  value: string;
  index: string;
};

export type FieldInput = {
  label: string;
  value: string;
  required: boolean;
};
interface FormConfig {
  initialValues: Record<string, Field>;
  validationSchema: {};
  inputList: FieldInput[];
  state: Record<string, Field[]>;
}

export class ImportFormBuilder {
  build({
    simpleImport,
    operation,
  }: {
    simpleImport: boolean;
    operation: ImportType;
  }): FormConfig {
    if (simpleImport) {
      return {
        initialValues: {
          nameField: { value: '', index: '' },
          documentField: { value: '', index: '' },
          phoneField: { value: '', index: '' },
          emailField: { value: '', index: '' },
          registrationNumberField: { value: '', index: '' },
        },
        validationSchema: yup.object({
          nameField: yup.object({
            value:
              operation === ImportType.insert
                ? yup.string().required('Este campo deve ser preenchido.')
                : yup.string().optional(),
          }),
          documentField: yup.object({
            value: yup.string().required('Este campo deve ser preenchido.'),
          }),
          phoneField: yup.object({
            value: yup.string().optional(),
          }),
          emailField: yup.object({
            value: yup.string().optional(),
          }),
          registrationNumberField: yup.object({
            value: yup.string().required('Este campo deve ser preenchido.'),
          }),
        }),
        inputList: [
          {
            label: 'Nome completo',
            value: 'nameField',
            required: operation === ImportType.insert ? true : false,
          },
          { label: 'CPF', value: 'documentField', required: true },
          { label: 'Celular', value: 'phoneField', required: false },
          { label: 'E-mail', value: 'emailField', required: false },
          { label: 'CNPJ', value: 'registrationNumberField', required: true },
        ],
        state: {
          nameField: [],
          documentField: [],
          phoneField: [],
          emailField: [],
          registrationNumberField: [],
          externalIdField: [],
        },
      };
    }
    return {
      initialValues: {
        nameField: { value: '', index: '' },
        documentField: { value: '', index: '' },
        phoneField: { value: '', index: '' },
        emailField: { value: '', index: '' },
        roleField: { value: '', index: '' },
        startDateField: { value: '', index: '' },
        managerField: { value: '', index: '' },
        pisField: { value: '', index: '' },
        departmentField: { value: '', index: '' },
        groupField: { value: '', index: '' },
        registrationNumberField: { value: '', index: '' },
        externalIdField: { value: '', index: '' },
      },
      validationSchema: yup.object({
        nameField: yup.object({
          value:
            operation === ImportType.insert
              ? yup.string().required('Este campo deve ser preenchido.')
              : yup.string().optional(),
        }),
        documentField: yup.object({
          value: yup.string().required('Este campo deve ser preenchido.'),
        }),
        phoneField: yup.object({
          value: yup.string().optional(),
        }),
        emailField: yup.object({
          value: yup.string().optional(),
        }),
        registrationNumberField: yup.object({
          value: yup.string().required('Este campo deve ser preenchido.'),
        }),
        roleField: yup.object({
          value: yup.string().optional(),
        }),
        groupField: yup.object({
          value: yup.string().optional(),
        }),
        departmentField: yup.object({
          value: yup.string().optional(),
        }),
        pisField: yup.object({
          value: yup.string().optional(),
        }),
        managerField: yup.object({
          value: yup.string().optional(),
        }),
        startDateField: yup.object({
          value: yup.string().optional(),
        }),
        externalIdField: yup.object({
          value: yup.string().optional(),
        }),
      }),
      inputList: [
        {
          label: 'Nome completo',
          value: 'nameField',
          required: operation === ImportType.insert ? true : false,
        },
        { label: 'CPF', value: 'documentField', required: true },
        { label: 'Celular', value: 'phoneField', required: false },
        { label: 'E-mail', value: 'emailField', required: false },
        { label: 'Cargo', value: 'roleField', required: false },
        { label: 'Grupo', value: 'groupField', required: false },
        { label: 'Departamento', value: 'departmentField', required: false },
        { label: 'PIS', value: 'pisField', required: false },
        { label: 'Líder imediato', value: 'managerField', required: false },
        { label: 'Data de admissão', value: 'startDateField', required: false },
        { label: 'CNPJ', value: 'registrationNumberField', required: true },
        { label: 'Matrícula', value: 'externalIdField', required: false },
      ],
      state: {
        nameField: [],
        documentField: [],
        phoneField: [],
        emailField: [],
        groupField: [],
        roleField: [],
        departmentField: [],
        managerField: [],
        startDateField: [],
        registrationNumberField: [],
        externalIdField: [],
      },
    };
  }
}
