import { useCompanyPermissions } from '@/hooks/useCompanyPermissions';
import { EmployeePageBreadcrumbs } from '@Components/EmployeePageBreadcrumbs';
import { EmployeePageHeader } from '@Components/EmployeePageHeader';
import { EmployeePageSkeleton } from '@Components/EmployeePageSkeleton';
import { Sections } from '@Components/Sections';
import { PageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import { useCheckPlatformPermission } from '@Hooks';
import { setEventTracking } from '@utils/analytics';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StatusEnum } from '../../common/types/StatusEnum';
import { useSession } from '../../common/user';
import { InviteCard } from './components/InviteCard';
import { ModalDeleteProfilePicture } from './components/ModalDeleteProfilePicture';
import { ModalEditProfilePicture } from './components/ModalEditProfilePicture';
import { TabList } from './components/TabList';
import { dataModel } from './dataModel';
import { useFetchCompanyEmployees } from './hooks/useFetchPersonManage';
import { useGetProfile } from './hooks/useGetProfile';
import { Content } from './styled';

export const PagePersonManage = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [modalEditProfilePicture, setModalEditProfilePicture] = useState(false);
  const [modalDeleteProfilePicture, setModalDeleteProfilePicture] =
    useState(false);
  const { permissions: companyRoles } = useCompanyPermissions();
  const { checkPlatformPermission } = useCheckPlatformPermission();
  const { id: employeeIdParam } = useParams() as { id: string };
  const { userId: loggedInEmployeeId, companyId } = useSession();
  const employeeId =
    employeeIdParam === 'me' ? loggedInEmployeeId : employeeIdParam;
  const { loading, employee } = useGetProfile(employeeId);
  const { companyEmployees, loading: loadingEmployeesList } =
    useFetchCompanyEmployees(companyId);

  const shouldShowInviteCard =
    employee?.status !== StatusEnum.ACTIVE &&
    employee?.status !== StatusEnum.INACTIVE;

  const permissionsTabAllowed = checkPlatformPermission(
    'core_manage_permissions',
  );
  const tabs = useMemo(
    () =>
      dataModel(
        { allowed: permissionsTabAllowed, companyRoles },
        employee,
        companyEmployees,
      ),
    [employee, companyEmployees, companyRoles, permissionsTabAllowed],
  );

  const handleSelectTab = (index: number) => {
    const selectedTab = tabs[index];
    if (selectedTab.key === 'permissions') {
      setEventTracking('employee_profile_page_permissions_tab_selected');
    }
    setSelectedTabIndex(index);
  };

  return (
    <PageContainer>
      <EmployeePageBreadcrumbs name={employee?.name || ''} />
      {loading || loadingEmployeesList || !employee ? (
        <EmployeePageSkeleton />
      ) : (
        <>
          <EmployeePageHeader
            name={employee.name}
            documentNumber={employee.documentNumber}
            profilePicture={employee.profilePicture}
            status={employee.status}
            startDate={employee.startDate}
            manager={employee.manager}
            showOptions={employeeId !== loggedInEmployeeId}
            showDocumentManagement
            employeeId={employeeId}
            invitationCode={employee.invitationCode}
            profilePictureEdit={true}
            profilePictureEditCallback={() => setModalEditProfilePicture(true)}
            profilePictureDeleteCallback={() =>
              setModalDeleteProfilePicture(true)
            }
          />
          {shouldShowInviteCard && employee.invitationDate && (
            <InviteCard
              status={employee.status}
              invitationDate={new Date(employee.invitationDate)}
              name={employee.name}
              employeeId={employee.id}
            />
          )}
          <ModalEditProfilePicture
            isOpen={modalEditProfilePicture}
            onClose={() => setModalEditProfilePicture(false)}
            employeeId={employeeId}
          />
          <ModalDeleteProfilePicture
            isOpen={modalDeleteProfilePicture}
            onClose={() => setModalDeleteProfilePicture(false)}
            name={employee?.name}
            source={employee?.profilePicture || ''}
            employeeId={employeeId}
          />
          <Content>
            <TabList
              tabs={tabs}
              selectedTab={tabs[selectedTabIndex]}
              setSelectedTab={(e) => handleSelectTab(e)}
            />
            <Sections
              sections={tabs[selectedTabIndex]?.sections}
              employeeId={employeeId}
            />
          </Content>
        </>
      )}
    </PageContainer>
  );
};
