import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const StyledCell = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 400px;
  gap: 8px;
  align-items: center;
`;

export const StyledCellTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledCellTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
`;

export const StyledCellDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledCellStatusContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const StyledCellStatus = styled.div`
  transform: translateY(-1px);
`;
