import { IconButton } from '@flash-tecnologia/hros-web-ui-v2';

import {
  ContentContainer,
  FooterContainer,
  HeaderContainer,
  IconContainer,
  StyledButton,
  StyledIcon,
  StyledLinkButton,
  StyledModal,
  StyledText,
  StyledTitle,
} from './styles';
import { useCancelInvite } from './useCancelInvite';

export const CancelScheduleModal = ({
  open,
  onClose,
  employeeId,
}: {
  open: boolean;
  onClose: () => void;
  employeeId: string;
}) => {
  const { cancelInvite, loading } = useCancelInvite(onClose);

  return (
    <StyledModal
      open={open}
      onClose={onClose}
      header={
        <HeaderContainer>
          <IconButton
            icon="IconX"
            onClick={onClose}
            variant="line"
            size="small"
          />
        </HeaderContainer>
      }
      footer={
        <FooterContainer>
          <StyledLinkButton
            variant="default"
            children="Fechar"
            onClick={onClose}
            style={{ alignSelf: 'center' }}
          />
          <StyledButton
            variant="primary"
            size="large"
            children="Cancelar agendamento"
            onClick={() => cancelInvite({ employeeId })}
            loading={loading}
            style={{ alignSelf: 'center' }}
          />
        </FooterContainer>
      }
    >
      <ContentContainer>
        <IconContainer>
          <StyledIcon
            name="IconAlertCircle"
            fill="none"
            size={62}
            stroke="1.2px"
          />
        </IconContainer>
        <StyledTitle setColor="error40" variant="body2">
          Atenção!
        </StyledTitle>
        <StyledTitle setColor="neutral10" variant="headline6">
          Tem certeza que deseja cancelar o seu agendamento salvo?
        </StyledTitle>
        <StyledText setColor="neutral50" variant="body3">
          O e-mail de boas vindas com acesso a nossa plataforma não será mais
          enviado na data agendada.
        </StyledText>
      </ContentContainer>
    </StyledModal>
  );
};
