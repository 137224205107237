import { trpc } from '@/api/client';
import dispatchToast from '@Utils/dispatchToast';
import { ErrorBoundary } from '@utils/ErrorBoundary';

export const useScheduleInvite = () => {
  const utils = trpc.useContext();
  const { mutateAsync, isLoading } = trpc.updateEmployeeBasicData.useMutation({
    onSuccess: async (_, { employeeId }) => {
      await utils.getEmployeeProfile.invalidate({ employeeId });
      dispatchToast({
        type: 'success',
        content: 'Agendamento editado com sucesso!',
      });
    },
    onError: (error: any) => {
      ErrorBoundary.captureException({
        ...error,
        name: 'ScheduleInvitationError',
      });
      dispatchToast({
        type: 'error',
        content: 'Erro ao editar agendamento',
        description:
          'Nós não conseguimos editar este agendamento, tente novamente mais tarde.',
      });
    },
  });

  return {
    scheduleInvite: mutateAsync,
    loading: isLoading,
  };
};
