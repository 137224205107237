import { Modal } from '@Components/Modal';
import { Button, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import {
  StyledAlertContainer,
  StyledDescription,
  StyledFooterContainer,
  StyledHeaderContainer,
  StyledIcon,
  StyledIconContainer,
  StyledTextContainer,
  StyledTitle,
  StyledWarningLabel,
} from './styles';

type ModalWarningProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  title: string;
  description: string;
  submitText: string;
};

export const ModalWarning = ({
  isOpen,
  onClose,
  title,
  description,
  submitText,
  onSubmit,
}: ModalWarningProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xs">
      <Modal.Header>
        <StyledHeaderContainer>
          <StyledAlertContainer>
            <StyledIconContainer>
              <StyledIcon name="IconAlertCircle" size={28} />
            </StyledIconContainer>
            <StyledWarningLabel variant="body3" weight={700}>
              Atenção!
            </StyledWarningLabel>
          </StyledAlertContainer>
        </StyledHeaderContainer>
      </Modal.Header>
      <Modal.Content>
        <StyledTextContainer>
          <StyledTitle variant="headline7" weight={700}>
            {title}
          </StyledTitle>
          <StyledDescription variant="body3" weight={400}>
            {description}
          </StyledDescription>
        </StyledTextContainer>
      </Modal.Content>
      <Modal.Footer>
        <StyledFooterContainer>
          <LinkButton onClick={() => onClose()} variant="neutral">
            Voltar e editar
          </LinkButton>
          <Button
            onClick={() => onSubmit()}
            variant="primary"
            variantType="negative"
            size="large"
          >
            {submitText}
          </Button>
        </StyledFooterContainer>
      </Modal.Footer>
    </Modal>
  );
};
