import { z } from 'zod';

const envSchema = z.object({
  EMPLOYEE_URL: z.string(),
  HROS_URL: z.string(),
});

const env = envSchema.parse(process.env);

export { env };
