import { useEffect, useMemo, useState } from 'react';
import { TableGrid } from './components/TableGrid';
import { TableContainer } from './styled';
import { ITableProps } from '../../types';
import { FilterStatusEnum, StatusEnum } from '@/common/types/StatusEnum';
import { debounce } from 'lodash';
import {
  useViewMode,
  VIEW_MODE_ENUM,
} from '@flash-tecnologia/hros-web-utility';
import { FilterDrawer } from './components/TableGrid/components/FilterDrawer';

export interface Filters {
  status: StatusEnum[] | FilterStatusEnum[] | null;
}

export const Table = ({
  companyId,
  fetchEmployees,
  totalEmployees,
  loading,
  employees,
  filters,
  setFilters,
}: ITableProps) => {
  //TODO - Pass the states of this component to a context, avoiding prop drilling
  const [searchState, setSearchState] = useState('');
  const { mode } = useViewMode();
  const [openDrawer, setOpenDrawer] = useState(false);

  const isUnifiedVision = useMemo(
    () => mode === VIEW_MODE_ENUM.ECONOMIC_GROUP,
    [mode],
  );

  const setDebouncedSearchState = debounce((value: string) => {
    setSearchState(value);
  }, 600);

  const [paginationState, setPaginationState] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const handlePageChange = ({
    pageNumber,
    pageSize,
  }: {
    pageNumber?: number;
    pageSize?: number;
  }) => {
    setPaginationState((prevState) => ({
      pageNumber: pageNumber || prevState.pageNumber,
      pageSize: pageSize || prevState.pageSize,
    }));
  };

  useEffect(() => {
    handlePageChange({ pageNumber: 1 });
  }, [searchState, filters]);

  useEffect(() => {
    fetchEmployees({
      ...(!isUnifiedVision && { companyId }),
      pageNumber: paginationState.pageNumber,
      pageSize: paginationState.pageSize,
      status: filters.status! as any,
      search: searchState,
    });
  }, [searchState, filters, paginationState, companyId, mode]);

  return (
    <TableContainer>
      <div data-onboarding="employee-management-employees-table">
        <TableGrid
          searchQuery={searchState}
          onSearch={(text) => setDebouncedSearchState(text)}
          enabledUnifiedVision={isUnifiedVision}
          totalCount={totalEmployees}
          data={employees}
          loading={loading}
          pagination={paginationState}
          onPagination={setPaginationState}
          onOpenFilter={() => setOpenDrawer(true)}
          filters={filters}
        />
      </div>
      <FilterDrawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        filters={filters}
        onFilter={setFilters}
      />
    </TableContainer>
  );
};
