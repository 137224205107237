import { Modal } from '@Components/Modal';
import {
  StyledCellContainer,
  StyledCellDescription,
  StyledCellDescriptionContainer,
  StyledCellIcon,
  StyledCellTextContainer,
  StyledCellTitle,
  StyledFooterContainer,
  StyledModalHeaderDescription,
  StyledModalHeaderTitle,
  StyledModalHeaderTitleWrapper,
  StyledTableContainer,
} from './styles';
import {
  Button,
  Checkbox,
  GenericProfilePicture,
  Icons,
  LinkButton,
  Table,
  tableControllers,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useEffect, useMemo, useState } from 'react';
import { trpc } from '@/api/client';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';

type ModalEmployeeCompaniesProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (companyId: string) => void;
  employeeId: string;
  loading?: boolean;
};

const TABLE_SIZE = 200;

export const ModalEmployeeMigration = ({
  isOpen,
  onClose,
  employeeId,
  onSubmit,
  loading,
}: ModalEmployeeCompaniesProps) => {
  const { data: companies } = trpc.getCompanies.useQuery();
  const { data: employee, mutate: fetchEmployee } =
    trpc.getEmployee.useMutation();
  const { selectedCompany: selectedCompanyPlatform } = useSelectedCompany();
  const [selectedCompany, setSelectedCompany] = useState<string>('');
  const [data, setData] = useState<typeof companies>([]);
  const [pagination, setPaginationState] = useState({
    pageNumber: 1,
    pageSize: TABLE_SIZE,
  });

  useEffect(() => {
    fetchEmployee({ employeeId });
  }, [employeeId]);

  useEffect(() => {
    if (companies?.length) {
      setData(companies);
    }
  }, [companies]);

  useEffect(() => {
    if (employee?.companyId) {
      setSelectedCompany(employee.companyId);
    }
  }, [employee]);

  const table = tableControllers.useTableColumns({
    total: companies?.length || 0,
    columns: [
      {
        id: 'select',
        maxSize: 10,
        cell: ({ row }) => (
          <Checkbox
            checked={row?.original?.id === selectedCompany}
            onChange={() => setSelectedCompany(row?.original?.id)}
            aria-label="Select row"
          />
        ),
      },
      {
        header: 'Empresas do grupo',
        accessorKey: 'name',
        minSize: 50,
        cell: (e) => (
          <StyledCellContainer>
            <GenericProfilePicture
              name={e?.cell?.row?.original?.name}
              size={40}
            />
            <StyledCellTextContainer>
              <StyledCellTitle variant="body3">
                {e?.cell?.row?.original?.name}
              </StyledCellTitle>
              <StyledCellDescriptionContainer>
                <StyledCellIcon name="IconBuilding" size={16} />
                <StyledCellDescription variant="body4">
                  {e?.cell?.row?.original?.registrationNumber}
                </StyledCellDescription>
              </StyledCellDescriptionContainer>
            </StyledCellTextContainer>
          </StyledCellContainer>
        ),
      },
    ],
    data: data || [],
    pagination: pagination,
    onPaginationChange: setPaginationState,
  });

  const onSearch = (name: string) => {
    const regex = new RegExp(name, 'i');

    const foundItems = companies?.filter((item) => regex.test(item.name));

    if (foundItems?.length) {
      setData(foundItems);
    }
  };

  const disabledButton = useMemo(() => {
    return selectedCompany === selectedCompanyPlatform.id;
  }, [selectedCompanyPlatform, selectedCompany]);

  return (
    <Modal size="sm" isOpen={isOpen} onClose={onClose}>
      <Modal.Header>
        <StyledModalHeaderTitleWrapper>
          <StyledModalHeaderTitle variant="headline8" weight={700}>
            Gerencie as empresas do grupo onde o colaborador pertence
          </StyledModalHeaderTitle>
          <StyledModalHeaderDescription variant="body4" weight={400}>
            Escolha em quais empresas do grupo o colaborador deve estar ativo.
            Ao selecionar uma empresa, a pessoa irá aparecer na página de Equipe
            e em todos os módulos contratados.
          </StyledModalHeaderDescription>
        </StyledModalHeaderTitleWrapper>
      </Modal.Header>
      <Modal.Content>
        <StyledTableContainer>
          <Table.Root>
            <Table.Search
              label="Buscar por empresa do grupo"
              onChange={(e) => onSearch(e.target.value)}
            />
            <Table.Grid.Root>
              <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
              {table.rows.map((row) => (
                <Table.Grid.Row key={row.id} row={row} />
              ))}
            </Table.Grid.Root>
          </Table.Root>
        </StyledTableContainer>
      </Modal.Content>
      <Modal.Footer>
        <StyledFooterContainer>
          <LinkButton onClick={() => onClose()} variant="neutral">
            Cancelar
          </LinkButton>
          <Button
            loading={loading}
            disabled={loading || disabledButton}
            onClick={() => onSubmit(selectedCompany as string)}
            variant="primary"
            size="medium"
          >
            Confirmar
            <Icons name="IconArrowRight" />
          </Button>
        </StyledFooterContainer>
      </Modal.Footer>
    </Modal>
  );
};
