export function formatPhoneNumber(phone: string): string {
  const cleaned = phone.replace(/\D/g, '');

  if (cleaned.length < 11 || cleaned.length > 13) {
    return cleaned;
  }

  const countryCode = `+${cleaned.slice(0, 2)}`;
  const areaCode = cleaned.slice(2, 4);
  const firstPart =
    cleaned.length === 11 ? cleaned.slice(4, 8) : cleaned.slice(4, 9);
  const secondPart =
    cleaned.length === 11 ? cleaned.slice(8) : cleaned.slice(9);

  return `${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
}
