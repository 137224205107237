import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeactivateConfirmationModal } from '@/pages/PageTable/components/Header/components/DeactivateConfirmationModal/deactivate-confirmation-modal';
import { IEmployeeOptions, TOption } from './types';
import {
  IconButton,
  Icons,
  Loader,
  Menu,
} from '@flash-tecnologia/hros-web-ui-v2';
import { LoadingOption, Option } from './styled';
import { ModalConfirmAction } from '@Components/ModalConfirmAction';
import {
  usePermissions,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility';
import { StatusEnum } from '@/common/types/StatusEnum';
import { useEmployeeOptions } from './useEmployeeOptions';
import { setEventTracking } from '@/utils';
import { env } from '@/lib/env';
import dispatchToast from '@utils/dispatchToast';
import { useFetchEmployeeLastInvite } from './useFetchEmployeeLastInvite';
import { useGetEmployeePermissions } from '@/pages/PageTable/hooks/useGetEmployeePermissions';
import { deactivateModalText } from '@utils/deactivateModalText';
import { useCanMigrateEmployee } from '@/pages/PageTable/components/ModalCreateEmployee/hooks/useCanMigrateEmployee';
import { useChangeEmployeeCompany } from '@/hooks/useChangeEmployeeCompany';
import { ModalEmployeeMigration } from '@Components/ModalEmployeeMigration';
import { ModalEmployeeMigrationSuccess } from '@Components/ModalEmployeeMigrationSuccess';

export const EmployeeOptions = memo(
  ({
    employeeId,
    userId,
    status,
    authUser,
    documentNumber,
    enableMigration,
  }: IEmployeeOptions) => {
    const navigate = useNavigate();
    const { selectedCompany } = useSelectedCompany();
    const permissions = usePermissions();
    const { isAdmin, companies: companiesWithPermissions } =
      useGetEmployeePermissions(employeeId!);

    const {
      deactivateModalIsOpen,
      setDeactivateModalIsOpen,
      deleteModalIsOpen,
      setDeleteModalIsOpen,
      deactivateEmployee,
      isDeactivating,
      deleteEmployee,
      isDeleting,
      isMigrateEmployeeModalOpen,
      setIsMigrateEmployeeModalOpen,
      isMigrateEmployeeSuccessModalOpen,
      setIsMigrateEmployeeSuccessModalOpen,
    } = useEmployeeOptions();

    const {
      loading: isFetchingLastInvitation,
      invitationCode,
      getLastInvitationId,
    } = useFetchEmployeeLastInvite();
    const [employeeCanMigrate, setEmployeeCanMigrate] = useState<
      boolean | undefined
    >(undefined);
    const { changeEmployeeCompany, isLoading: loadingMigration } =
      useChangeEmployeeCompany({
        options: {
          onSuccess: () => {
            setIsMigrateEmployeeSuccessModalOpen(true);
            setIsMigrateEmployeeModalOpen(false);
          },
          onError: () => {
            dispatchToast({
              type: 'error',
              content:
                'Não foi possível transferir a pessoa para esta empresa. Por favor, tente novamente.',
            });
          },
        },
      });

    const { canMigrate } = useCanMigrateEmployee();

    const checkCanMigrate = useCallback(async () => {
      const canMigrateResult = await canMigrate({ employeeId });
      return setEmployeeCanMigrate(canMigrateResult.canMigrate);
    }, [employeeId]);

    const options: TOption[] = useMemo(() => {
      const hasViewProfilePermission =
        permissions.isAdmin ||
        permissions.companies
          .find((c) => c.id === selectedCompany.id)
          ?.permissions.includes('core_view_employees_profile');

      let opt: TOption[] = [
        {
          key: 'profile',
          onClick: () => navigate(`/employees/${employeeId}/profile`),
          children: (
            <Option disabled={!hasViewProfilePermission}>
              <Icons name="IconEye" fill="transparent" />
              Ver perfil da pessoa
            </Option>
          ),
        },
      ];

      const hasDeactivatePermission =
        permissions.isAdmin ||
        permissions.companies
          .find((c) => c.id === selectedCompany.id)
          ?.permissions.includes('core_deactivate_employee');

      if (
        status === StatusEnum.ACTIVE &&
        employeeId !== userId &&
        authUser?.username !== documentNumber
      ) {
        opt.push({
          key: 'deactivate',
          onClick: () => setDeactivateModalIsOpen(true),
          children: (
            <Option disabled={!hasDeactivatePermission}>
              <Icons name="IconUserOff" fill="transparent" />
              Desativar pessoa
            </Option>
          ),
        });
      } else if (
        status !== StatusEnum.INACTIVE &&
        employeeId !== userId &&
        authUser?.username !== documentNumber
      ) {
        opt.push({
          key: 'delete',
          onClick: () => setDeleteModalIsOpen(true),
          children: (
            <Option disabled={!hasDeactivatePermission}>
              <Icons name="IconUserOff" fill="transparent" />
              Deletar pessoa
            </Option>
          ),
        });
      }

      if (status === StatusEnum.INVITATION_SENT && invitationCode) {
        opt.push({
          key: 'copyFirstAccessLink',
          onClick: () => {
            setEventTracking('backbone_copy_invite_clicked', {
              employeeId,
            });
            navigator.clipboard.writeText(
              `${env.HROS_URL}/authentication/first-access?invitationToken=${invitationCode}`,
            );
            dispatchToast({
              type: 'success',
              content: 'Link copiado com sucesso',
            });
          },
          children: (
            <Option>
              <Icons name="IconLink" fill="transparent" />
              Copiar link de primeiro acesso
            </Option>
          ),
        });
      }

      if (isFetchingLastInvitation) {
        opt.push({
          key: 'loading',
          children: (
            <LoadingOption>
              <Loader size="small" variant="primary" />
            </LoadingOption>
          ),
        });
      }

      if (employeeCanMigrate && enableMigration) {
        opt.push({
          key: 'migrate',
          onClick: () => setIsMigrateEmployeeModalOpen(true),
          children: (
            <Option>
              <Icons name="IconArrowsShuffle" fill="transparent" />
              Movimentar pessoa
            </Option>
          ),
        });
      }

      return opt;
    }, [
      userId,
      employeeId,
      permissions,
      selectedCompany,
      invitationCode,
      isFetchingLastInvitation,
      employeeCanMigrate,
      enableMigration,
    ]);

    return (
      <>
        <Menu
          type={'select'}
          options={options}
          disableAutoFocusItem={true}
          anchorOrigin={{ vertical: 0, horizontal: -210 }}
        >
          <IconButton
            variant="line"
            size="small"
            icon="IconDotsVertical"
            onClick={() => {
              if (status === StatusEnum.INVITATION_SENT) {
                getLastInvitationId(employeeId);
              }
              if (employeeCanMigrate === undefined) {
                checkCanMigrate();
              }
            }}
          />
        </Menu>
        {deactivateModalIsOpen && (
          <DeactivateConfirmationModal
            isOpen={deactivateModalIsOpen}
            loading={isDeactivating}
            isAdmin={isAdmin}
            companiesWithPermissions={companiesWithPermissions}
            submitText="Desativar pessoa"
            confirmWord={
              isAdmin || companiesWithPermissions?.length
                ? deactivateModalText.admin.confirmWord
                : undefined
            }
            onClose={() => setDeactivateModalIsOpen(false)}
            onSubmit={() => {
              setEventTracking('employee_deactivate_confirmed_clicked', {
                deactivatedEmployee: employeeId!,
              });
              deactivateEmployee({
                employeeId,
                companyId: selectedCompany.id,
              });
            }}
          />
        )}
        {deleteModalIsOpen && (
          <ModalConfirmAction
            isOpen={deleteModalIsOpen}
            loading={isDeleting}
            title="Tem certeza que deseja deletar esta pessoa?"
            description="Ao deletá-la, esta pessoa perderá o acesso à plataforma e serviços, e não conseguirá acessar a plataforma. Será preciso um novo cadastro."
            submitText="Deletar"
            onClose={() => setDeleteModalIsOpen(false)}
            onSubmit={() =>
              deleteEmployee({
                employeeId,
                companyId: selectedCompany.id,
              })
            }
          />
        )}
        {isMigrateEmployeeModalOpen && (
          <ModalEmployeeMigration
            isOpen={isMigrateEmployeeModalOpen}
            onClose={() => setIsMigrateEmployeeModalOpen(false)}
            employeeId={employeeId}
            loading={loadingMigration}
            onSubmit={(selectedCompany) =>
              changeEmployeeCompany(employeeId, selectedCompany)
            }
          />
        )}

        {isMigrateEmployeeSuccessModalOpen && (
          <ModalEmployeeMigrationSuccess
            isOpen={isMigrateEmployeeSuccessModalOpen}
            onClose={() => setIsMigrateEmployeeSuccessModalOpen(false)}
            employeeId={employeeId}
          />
        )}
      </>
    );
  },
);
