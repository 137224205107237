import {
  IconsProps,
  PillButton,
  Skeleton,
} from '@flash-tecnologia/hros-web-ui-v2';
import * as S from './styles';

type BigNumberProps = {
  title: string;
  content: string;
  onClick: () => void;
  icon: IconsProps['name'];
  loading?: boolean;
};

export const BigNumber = (props: BigNumberProps) => {
  return (
    <S.StyledBigNumber>
      <S.StyledIconContainer>
        <PillButton
          size="extra-small"
          icon={props.icon}
          type="primary"
          variant="pink"
        />
      </S.StyledIconContainer>
      <S.StyledTitle weight={600} variant="body4">
        {props.title}
      </S.StyledTitle>
      {!props.loading ? (
        <S.StyledNumber weight={700} variant="headline8">
          {props.content}
        </S.StyledNumber>
      ) : (
        <Skeleton width="40%" height={24} />
      )}
      <S.StyledButtonContainer onClick={() => props.onClick()}>
        <S.StyledButtonText variant="caption" weight={700}>
          Mostrar
        </S.StyledButtonText>
        <S.StyledButtonIcon name="IconChevronsDown" size={18} />
      </S.StyledButtonContainer>
    </S.StyledBigNumber>
  );
};
