import { trpc } from '@/api/client';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';

type UseCreateMigrationFlowProps = {
  company: {
    name: string;
    id: string;
    registrationNumber: string;
  };
  updateCalls: Dispatch<SetStateAction<number>>;
  errorsRef: MutableRefObject<
    {
      documentNumber: string;
      reason: string;
    }[]
  >;
};

export const useCreateMigrationFlow = ({
  company,
  updateCalls,
  errorsRef,
}: UseCreateMigrationFlowProps) => {
  const { mutateAsync: getEmployee } = trpc.getEmployee.useMutation();
  const { mutateAsync: findEmployees } =
    trpc.getEmployeesPaginated.useMutation();
  const { mutateAsync: canMigrateEmployee } =
    trpc.canMigrateEmployee.useMutation();
  const { mutateAsync: migrateEmployee } =
    trpc.changeEmployeeCompany.useMutation();

  const createMigrationFlow = async (employeeId: string) => {
    const currentEmployee = await getEmployee({ employeeId });

    const existsInTargetCompany = await findEmployees({
      companyId: company.id,
      pageSize: 1,
      page: 1,
      documentNumbers: currentEmployee.documentNumber,
    });

    if (existsInTargetCompany.employees.length) {
      errorsRef.current.push({
        documentNumber: currentEmployee.documentNumber,
        reason: `O colaborador já existe na empresa ${company.name} - ${company.registrationNumber}.`,
      });
      updateCalls((calls) => calls + 1);
      return;
    }

    const { canMigrate } = await canMigrateEmployee({ employeeId });

    if (!canMigrate) {
      errorsRef.current.push({
        documentNumber: currentEmployee.documentNumber,
        reason:
          'O colaborador não pode ser migrado devido à possível existência de depósitos pendentes em seu cartão corporativo.',
      });
      updateCalls((calls) => calls + 1);
      return;
    }

    await migrateEmployee({
      companyId: company.id,
      employeeId,
    });

    updateCalls((calls) => calls + 1);
  };

  return { createMigrationFlow };
};
